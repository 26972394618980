<template>
  <a-table
    class="gx-table-responsive"
    :columns="columns"
    :data-source="teams"
    :loading="dataLoading"
  >
    <div slot="status" slot-scope="text, row" class="gx-text-left">
      <a-tag
        v-if="row.status === 'accept'"
        color="#27ae60"
        style="margin-bottom: 0px; border-radius: 30px"
        >Active</a-tag
      >
      <a-tag
        v-if="row.status === 'invite'"
        color="#f39c12"
        style="margin-bottom: 0px; border-radius: 30px"
        >Invite Pending</a-tag
      >
      <!-- <a-tag
          v-if="text === 'request'"
          color="#8e44ad"
          style="margin-bottom: 0px; border-radius: 30px"
          >Has Requested to Join</a-tag
        > -->
      <a-tag
        v-if="row.status === 'cancel'"
        color="#8e44ad"
        style="margin-bottom: 0px; border-radius: 30px"
        >No Longer a Member</a-tag
      >

      <a-tag
        v-if="row.status === 'decline'"
        color="#c0392b"
        style="margin-bottom: 0px; border-radius: 30px"
        >Declined Invitation</a-tag
      >
    </div>
    <div slot="operations" slot-scope="text, row" class="gx-text-left">
      <a-button
        v-if="row.status === 'accept'"
        class="gx-btn-cyan"
        size="small"
        style="margin-left: 5px"
        @click="emitEdit(row.id)"
      >
        Manage
      </a-button>
      <a-button
        v-if="row && row.role !== 'Admin' && row.status === 'accept'"
        class="gx-btn-danger"
        size="small"
        style="margin-left: 5px"
        @click="removeAdmin(row.id)"
      >
        Remove
      </a-button>
    </div>
  </a-table>
</template>

<script>
import timeMixing from "@/mixins/time";
import nCurrency from "@/mixins/currency";
import { clubAdminsService } from "@/common/api/api.service";
import notifications from "@/common/notifications/notification.service";

const columns = [
  {
    title: "Name",
    dataIndex: "preferred_name",
    key: "id",
  },
  {
    title: "Email",
    dataIndex: "work_email",
  },
  {
    title: "Phone",
    dataIndex: "phone",
  },
  {
    title: "Status",
    scopedSlots: {
      customRender: "status",
    },
  },
  {
    title: "Actions",
    scopedSlots: {
      customRender: "operations",
    },
  },
];

export default {
  name: "ClubTeamsTable",
  mixins: [nCurrency, timeMixing],
  data() {
    return {
      columns,
      teams: [],
      dataLoading: true,
    };
  },
  mounted() {
    this.getAdmins();
  },
  methods: {
    emitEdit(user_id) {
      this.$emit("edit", user_id);
    },
    removeAdmin(admin_id) {
      clubAdminsService
        .remove({
          admin_id,
          url: window.location.origin,
        })
        .then((resp) => {
          if (resp.data.success) {
            this.getAdmins();
            notifications.success("Coach removed successfully");
          } else {
            notifications.success(resp.data.message);
          }
        });
    },
    getAdmins() {
      clubAdminsService.query().then((resp) => {
        if (resp.data.success) {
          this.teams = resp.data.result;
        }
        this.dataLoading = false;
      });
    },
  },
};
</script>

<style scoped></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-table", {
    staticClass: "gx-table-responsive",
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.teams,
      loading: _vm.dataLoading,
    },
    scopedSlots: _vm._u([
      {
        key: "status",
        fn: function (text, row) {
          return _c(
            "div",
            { staticClass: "gx-text-left" },
            [
              row.status === "accept"
                ? _c(
                    "a-tag",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "border-radius": "30px",
                      },
                      attrs: { color: "#27ae60" },
                    },
                    [_vm._v("Active")]
                  )
                : _vm._e(),
              row.status === "invite"
                ? _c(
                    "a-tag",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "border-radius": "30px",
                      },
                      attrs: { color: "#f39c12" },
                    },
                    [_vm._v("Invite Pending")]
                  )
                : _vm._e(),
              row.status === "cancel"
                ? _c(
                    "a-tag",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "border-radius": "30px",
                      },
                      attrs: { color: "#8e44ad" },
                    },
                    [_vm._v("No Longer a Member")]
                  )
                : _vm._e(),
              row.status === "decline"
                ? _c(
                    "a-tag",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "border-radius": "30px",
                      },
                      attrs: { color: "#c0392b" },
                    },
                    [_vm._v("Declined Invitation")]
                  )
                : _vm._e(),
            ],
            1
          )
        },
      },
      {
        key: "operations",
        fn: function (text, row) {
          return _c(
            "div",
            { staticClass: "gx-text-left" },
            [
              row.status === "accept"
                ? _c(
                    "a-button",
                    {
                      staticClass: "gx-btn-cyan",
                      staticStyle: { "margin-left": "5px" },
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.emitEdit(row.id)
                        },
                      },
                    },
                    [_vm._v("\n      Manage\n    ")]
                  )
                : _vm._e(),
              row && row.role !== "Admin" && row.status === "accept"
                ? _c(
                    "a-button",
                    {
                      staticClass: "gx-btn-danger",
                      staticStyle: { "margin-left": "5px" },
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.removeAdmin(row.id)
                        },
                      },
                    },
                    [_vm._v("\n      Remove\n    ")]
                  )
                : _vm._e(),
            ],
            1
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }